.contact_sec1 {
  margin-top: 130px;
}
.contact_sec1 h3 {
  color: #ff6210;
}
@media screen and (max-width: 767px) {
  .contact_sec1 strong {
    font-size: 15px;
  }
  .contact_sec1 h3 {
    font-size: 25px;
    color: #ff6210;
  }
  .contact_sec1 .order-md-0 {
    margin-top: 30px !important;
    text-align: center;
  }
}
