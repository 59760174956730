@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: poppins !important;
}

body {
  background-color: #fef1e4;
}

.floating-icons {
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
}
.floating-icons a:hover {
  text-decoration: none !important;
}
.icon {
  margin-top: 10px;
  font-size: 25px;
  color: #000; /* Change the color as needed */
}

.icon {
  animation: zoomInOut 1s infinite alternate; /* Apply the zoomInOut animation */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1); /* Start with normal size */
  }
  50% {
    transform: scale(1.1); /* Zoom in */
  }
  100% {
    transform: scale(1); /* Zoom out */
  }
}

.green_button:hover {
  background-color: #25d366;
}
.green_fixed_button {
  border-radius: 5px;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px;
  border: none;
  background-color: #25d366;
  display: flex;
  align-items: center;
  width: 185px;
  justify-content: space-evenly;
}
.green_fixed_button i {
  font-size: 22px;
}
.red_fixed_button i {
  font-size: 18px;
}
.red_fixed_button {
  border-radius: 5px;
  /* box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, 0.075); */
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px;
  border: none;
  background-color: #ff6210;
  display: flex;
  align-items: center;
  width: 185px;
  justify-content: space-around;
}
.red_button:hover {
  background-color: #ff6210;
}

.home_sec3 {
  padding: 100px 0px;
}
.home_sec3 img {
  border-radius: 10px;
}
.home_sec3 h1 {
  text-align: center;
  color: #ff6210;
  font-size: 35px;
  margin-bottom: 25px;
}
.home_sec3 p {
  color: #756868;
  text-indent: 40px;
  font-size: 15px;
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .home_sec3 p {
    margin-top: 20px;
    text-align: center;
  }
}

.home_sec5 h1 {
  text-align: center;
  color: #ff6210;
  font-size: 35px;
  margin-bottom: 25px;
}
.home_sec5 img {
  margin-top: 15px;
  border-radius: 7px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.home_sec6 {
  margin-top: 100px;
  background-color: #ff6210;
  padding: 30px;
  text-align: center;
}
.home_sec6 h1 {
  color: #ffffff;
}
.home_sec6 button {
  background-color: #fef1e4;
  color: #ff6210;
  padding: 10px 25px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  font-size: 17px;
}
