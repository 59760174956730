.home_sec1 {
  padding: 100px 0px;
}
.home_sec01 h2 {
  color: #ff6210;
}
.home_sec01 p {
  color: #756868;
}
.home_sec02 h4 {
  font-size: 16px;
  color: black;
  text-align: center;
  font-weight: 600;
}

.home_sec02 .form-control {
  margin-top: 25px;
  height: 47px;
}
.home_sec02 .form-control:focus {
  box-shadow: none !important;
  border: 1px dotted #ff6210 !important;
}
.home_sec02 button {
  width: 100%;
  margin-top: 25px;
  height: 47px;
  background-color: #ff6210;
  padding: 0px 30px;
  border: none;
  color: #ffffff;
  border-radius: 5px;
}

.home_sec2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.733), #00000080),
    url("../../images/home1.jpg");
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.home_sec2 h1 {
  color: #ffffff;
}
.home_sec2 button {
  margin-top: 25px;
  height: 47px;
  background-color: #ff6210;
  padding: 0px 30px;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  font-size: 17px;
}

.home_sec4 {
  padding: 0px 0px 100px 0px;
}
.home_sec4 h1 {
  text-align: center;
  color: #ff6210;
  font-size: 35px;
  margin-bottom: 25px;
}
.home_sec4 .col-md-4 {
  display: flex;
  margin-top: 25px;
}
.home_sec4 .col-md-4 i {
  color: #ff6210;
  font-size: 40px;
  margin-right: 15px;
}
.home_sec4 h6 {
  color: #ff6210;
}
.home_sec4 p {
  color: #756868;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .home_sec01 h2 {
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
  }
  .home_sec01 p {
    text-align: center;
  }
  .home_sec02 form button {
    text-align: center;
    margin-top: 25px;
    height: 47px;
    background-color: #ff6210;
    padding: 0px 30px;
    border: none;
    color: #ffffff;
    border-radius: 5px;
    width: 100%;
  }
  .home_sec2 h1 {
    font-size: 25px;
  }
  .home_sec3 p {
    margin-top: 20px;
    text-align: center;
  }
  .home_sec4 h1 {
    font-size: 25px;
  }
  .home_sec4 p {
    color: #756868;
    font-size: 12px;
  }
  .home_sec4 .col-md-4 i {
    color: #ff6210;
    font-size: 30px;
    margin-right: 15px;
  }
  .home_sec6 h1 {
    font-size: 25px;
  }
  .home_sec6 button {
    font-size: 15px;
  }
}
