.footer {
  background-color: #353535;
  padding: 60px 0px;
  text-align: center;
}

.footer img {
  width: 250px;
}
.footer p {
  color: #a2a2a2;
  margin-top: 17px;
}
.footer h4 {
  color: #ff6210;
  font-size: 20px;
  text-align: center;
}
.footer ul {
  padding-left: 0px !important;
  text-align: center;
}
.footer ul li {
  list-style-type: none !important;
  margin-top: 10px;
  cursor: pointer;
  color: #ffffff;
}
.footer ul li:hover {
  color: #ff6210;
}

.copyRight {
  text-align: center !important;
}
.copyRight {
  color: #ff6210 !important;
  margin-top: 25px !important;
}
